import React from "react"
import "../FestTitles.scss"
import "./14FestHero.scss"
import { langVersion, isBrowser } from "../../../accessors"

const Fest14Hero = () => {
  return (
    <section id="fest14-hero" className="fest__hero">
      <article className="fest__jumbotron">
        <article className="fest__hero-box fest__hero-box--left">
          <>
            <h3 className="fest__article-title--old">
              {isBrowser()
                ? langVersion("Between.Pomiędzy", "Between.Pomiędzy")
                : "Content is loading..."}
            </h3>
            <h1 className="fest__section-title--old">
              {langVersion("Festiwal Rozproszony", "Dispersed Festival")}
            </h1>
          </>
        </article>
        <article className="fest__hero-box fest__hero-box--right">
          {isBrowser() ? (
            <>
              <p className="fest__article-text">
                {langVersion(
                  "Between.Pomiędzy to festiwal dwujęzyczny. Część materiałów prezentujemy w języku polskim. Część materiałów w języku angielskim. Gdzie to możliwe zapewniamy obie wersje językowe.",
                  "This is a bilingual festival. Some materials are in English. Others in Polish. We’ve tried to provide both versions wherever possible."
                )}
              </p>
              <a
                className="fest__button-link"
                href={langVersion("#fest__program", "#fest__program")}
                title={langVersion("Idź do wydarzeń", "Go to Events section")}
              >
                <button className="fest__button-main">
                  {langVersion("wydarzenia", "events")}
                </button>
              </a>
            </>
          ) : (
            <div>Content is loading...</div>
          )}
        </article>
      </article>
    </section>
  )
}

export default Fest14Hero
